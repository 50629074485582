import React, { useRef, useEffect, useLayoutEffect } from "react"

import { useWindowSize } from "../hooks/windowsize"

const useAnimationFrame = callback => {
  const callbackRef = useRef(callback)
  const frameRef = useRef()
  const timerRef = useRef()

  useLayoutEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useLayoutEffect(() => {
    const loop = time => {
      frameRef.current = requestAnimationFrame(loop)

      let dt = 0
      if (timerRef.current !== undefined && timerRef.current !== null)
        dt = time - timerRef.current

      const callback = callbackRef.current
      callback(dt / 1000)

      timerRef.current = time
    }

    frameRef.current = requestAnimationFrame(loop)
    return () => cancelAnimationFrame(frameRef.current)
  }, [])
}

const CanvasGrid = props => {
  const size = useWindowSize()

  const canvasRef = React.useRef(null)

  const theme = props.theme || "dark"

  const scaleCanvas = (canvas, context, width, height) => {
    // assume the device pixel ratio is 1 if the browser doesn't specify it
    const devicePixelRatio = window.devicePixelRatio || 1

    // determine the 'backing store ratio' of the canvas context
    const backingStoreRatio =
      context.webkitBackingStorePixelRatio ||
      context.mozBackingStorePixelRatio ||
      context.msBackingStorePixelRatio ||
      context.oBackingStorePixelRatio ||
      context.backingStorePixelRatio ||
      1

    // determine the actual ratio we want to draw at
    const ratio = devicePixelRatio / backingStoreRatio

    if (devicePixelRatio !== backingStoreRatio) {
      // set the 'real' canvas size to the higher width/height
      canvas.width = width * ratio
      canvas.height = height * ratio

      // ...then scale it back down with CSS
      canvas.style.width = width + "px"
      canvas.style.height = height + "px"
    } else {
      // this is a normal 1:1 device; just scale it simply
      canvas.width = width
      canvas.height = height
      canvas.style.width = ""
      canvas.style.height = ""
    }

    // scale the drawing context so everything will work at the higher ratio
    context.scale(ratio, ratio)
  }

  const draw = () => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")

    ctx.clearRect(0, 0, size.width, size.height)

    const totalRects = size.width > 992 ? 7 : 4

    const rectWidth = size.width / totalRects

    const start = {
      x: (window.pageYOffset * 0.1) % rectWidth,
      y: (window.pageYOffset * 0.1) % rectWidth,
    }

    ctx.strokeStyle = theme === "dark" ? "#3B3838" : "#CCC8C3"

    let offset = 0

    while (offset < size.width) {
      ctx.beginPath()
      ctx.moveTo(start.x + offset, 0)
      ctx.lineTo(start.x + offset, size.height)
      ctx.stroke()
      offset += rectWidth
    }

    offset = 0
    while (offset < size.height) {
      ctx.beginPath()
      ctx.moveTo(0, start.y + offset)
      ctx.lineTo(size.width, start.y + offset)
      ctx.stroke()
      offset += rectWidth
    }
  }

  useAnimationFrame(draw)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")

    scaleCanvas(canvas, ctx, size.width, size.height)

    canvas.width = size.width * window.devicePixelRatio
    canvas.height = size.height * window.devicePixelRatio
    canvas.style.width = canvas.width + "px"
    canvas.style.height = canvas.height + "px"
  }, [size])

  return (
    <canvas ref={canvasRef} className={`canvas-grid ${props.class}`}>
      Your browser doesn't support canvas!
    </canvas>
  )
}

export default CanvasGrid
